<template>
    <v-dialog v-model="dialog" scrollable width="600px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" class="text-lowercase mr-4" dark small v-on="on">добавить товар</v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Параметры нового товара
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row no-gutters>
                            <v-col cols="12" md="4" sm="6">
                                <v-text-field dense color="primary" 
                                    label="Код товара" 
                                    class="mr-3"
                                    v-model="id" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="6">
                                <v-select :items="items" dense label="Категория" v-model="category">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" label="Название товара(ru)" v-model="name_ru" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field color="primary" label="Название товара (ro)" v-model="name_ro" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field color="primary" label="Название товара (en)" v-model="name_en" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" class="mr-3">
                                <v-text-field color="primary" label="Цена (MDL)" v-model.number="price" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" class="mr-3">
                                <v-text-field color="primary" label="Цена (RON)" v-model.number="price_RON" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <v-text-field color="primary" label="Цена (USD)" v-model.number="price_USD" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-ro" label="Описание на русском" auto-grou
                                    v-model.lazy="description_ru">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-ro" label="Описание на румынском" auto-grou
                                    v-model.lazy="description_ro">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-ro" label="Описание на английском" auto-grou
                                    v-model.lazy="description_en">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                                    <v-text-field dense color="primary" 
                                        label="Цвета товара на английском (через пробел)" 
                                        class="mr-3"
                                        v-model="colors" 
                                        type="text"
                                        :rules="[rules.required]">
                                    </v-text-field>
                                </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="Boolean(error)"
                    @click="addProduct">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            items: ['sets', 'newborn', 'premature'],
            rules: {
                required: value => !!value || 'required field',
            },
            //props
            id: '',
            colors: 'white',
            category: 'newborn',
            name_ru: '',
            name_ro: '',
            name_en: '',
            price: 0,
            price_RON: 0,
            price_USD: 0,
            description_ro: '',
            description_ru: '',
            description_en: '',
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.dialog = false;
        },
        async addProduct() {
            if (this.$refs.form.validate()) {
                const colors = this.colors.split(' ');
                const product = {
                    id: this.id,
                    colors: colors,
                    name_en: this.name_en,
                    name_ro: this.name_ro,
                    name_ru: this.name_ru,
                    category: this.category,
                    price: this.price,
                    price_RON: this.price_RON,
                    price_USD: this.price_USD,
                    description_en: this.description_en,
                    description_ro: this.description_ro,
                    description_ru: this.description_ru,
                }
                //console.log(product)
                try {
                    await this.$store.dispatch('addProduct', product);
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>