<template>
    <v-container fluid>
        <div v-if="articles.length">
            <!--add article-->
            <v-row class="my-2" no-gutters>
                <add-article></add-article>
            </v-row>
            <!--- header -->
            <v-row class="subtitle-2 font-weight-bold mb-2">
                <v-col v-for="(header, idx) in headers" :key="idx" class="text-left">
                    {{ header }}
                </v-col>
            </v-row>
            <v-expansion-panels focusable>
                <v-expansion-panel v-for="article in paginatedArticles" :key="article.id">
                    <v-expansion-panel-header>
                        <!--- table row -->
                        <v-row class="body-2" no-gutters>
                            <v-col class="text-left">{{ article.id }}</v-col>
                            <v-col class="text-left">{{ dateLocale(article.date) }}</v-col>
                            <v-col class="text-left">{{ article.title_ro }}</v-col>
                            <v-col class="text-left">{{ article.title_ru }}</v-col>
                            <v-col class="text-left">{{ article.title_en }}</v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2">
                        <v-divider></v-divider>
                        <!--- text -->
                        <v-row>
                            <v-col class="text-left">{{ article.text_ro }}</v-col>
                            <v-col class="text-left">{{ article.text_ru }}</v-col>
                            <v-col class="text-left">{{ article.text_en }} </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <!--actions-->
                        <v-row justify="end" class="my-2">
                            <edit-article :article="article"></edit-article>
                            <delete-article :article="article"></delete-article>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="text-center mt-4">
                <v-pagination v-model="page" :length="pages">
                </v-pagination>
            </div>
        </div>
        <v-row v-else>в блоге пока нет статей</v-row>
    </v-container>
</template>
<script>
import addArticle from './articles/AddArticle.vue';
import editArticle from './articles/EditArticle.vue';
import deleteArticle from './articles/DeleteArticle.vue'

export default {
    components: { addArticle, editArticle, deleteArticle },
    data() {
        return {
            headers: ['номер', 'дата', 'название статьи (румынский)', 'название статьи (русский)', 'название статьи (английский)'],
            page: 1,//for pagination
            NUM_OF_ARTICLES: 6,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        articles() {
            return this.$store.getters.articles;
        },
        startIndex() {
            return this.NUM_OF_ARTICLES * (this.page - 1);
        },
        endIndex() {
            return this.NUM_OF_ARTICLES * this.page;
        },
        pages() {
            const nums = this.articles?.length;
            const pages = Math.ceil(nums / this.NUM_OF_ARTICLES);
            return pages > 0 ? pages : 1;
        },
        paginatedArticles() {
            return this.articles?.slice(this.startIndex, this.endIndex);
        },
    },
    methods: {
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleDateString(this.locale);
            }
            return 'no date'
        },

    },
}
</script>
