<template>
    <v-dialog v-model="dialog" scrollable width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" icon v-on="on">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Редактирование данных клиента
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row justify="center">
                           
                            <v-col cols="12" md="6">
                                <v-text-field dense color="primary" label="Имя клиента" v-model="firstName" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field dense color="primary" label="Фамилия клиента" v-model="lastName" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                             <v-col cols="12" md="4">
                                <v-select :items="items" dense 
                                    label="Роль" 
                                    v-model="role">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field dense color="primary" 
                                label="Телефон клиента" v-model="phone" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field dense color="primary" 
                                    label="Почта клиента" 
                                    v-model="email" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select dense :menu-props="{ top: true, offsetY: true }" :label="'Country' | localize"
                                    v-model="country" :items="countries" :rules="[rules.required]">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-text-field dense color="primary" label="Город" v-model="city" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field dense color="primary" 
                                    label="Адрес клиента" v-model="address" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="!valid || Boolean(error)"
                    @click="editUser">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['user'],
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            items: ['USER', 'ADMIN'],
            rules: {
                required: value => !!value || 'required field',
            },
            //props
            role: this.user.role,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            phone: this.user.phone,
            country: this.user.country,
            city: this.user.city,
            address: this.user.address,
            email: this.user.email,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        countries() {
            return ['Moldova', 'Romania'];
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.role = this.user.role;
            this.firstName = this.user.firstName;
            this.lastName = this.user.lastName;
            this.phone = this.user.phone;
            this.country = this.user.country;
            this.city = this.user.city;
            this.address = this.user.address;
            this.email= this.user.email;
        },
        async editUser() {
            if (this.$refs.form.validate()) {
                const payload = {
                    id: this.user.id,
                    role: this.role,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    country: this.country,
                    city: this.city,
                    address: this.address,
                    email: this.email,
                }

                try {
                    await this.$store.dispatch('editUser', payload);
                    this.dialog = false;
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>