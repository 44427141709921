<template>
    <v-dialog v-model="dialog" scrollable width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" text class="text-lowercase" color="primary">
                {{ image.name }}
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="error--text subtitle-2 pt-6">
                Удалить данное изображение товара {{ id }}  ({{ name }})?
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-row no-gutters>
                        <v-col cols="12" md="12" sm="12">
                            <v-img
                            :src="img.url"
                            class="mr-2"
                            ></v-img>
                        </v-col>
                        <v-col>{{ img.name }}</v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="Boolean(error)"
                    @click="deleteProductImage">Удалить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['product', 'image'],
    data() {
        return {
            dialog: false,
            error: '',
            //props
            id: this.product.id,
            name: this.product.name_ru,
            img: this.image,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        async deleteProductImage() {
            if (this.image && this.product) {
               
                const images = this.product.images.filter(img => img.name !== this.image.name)

                const forEditing = {
                    id: this.product.id,
                    images: images
                }

                const path = `products/${this.product.category}/${this.image.name}`
                
                try {
                    await this.$store.dispatch('editProduct', forEditing);
                    await this.$store.dispatch('deleteImage', path);
                }
                catch (error) {
                    await this.$store.dispatch('setError', error.message)
                } 
            }
        }
    }
}
</script>