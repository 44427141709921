<template>
    <v-dialog v-model="dialog" scrollable width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small v-on="on">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6 primary--text">
                Редактирование продукта. Код {{ product.id }}
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <v-text-field dense
                                    color="primary" 
                                    label="Название товара"
                                    v-model="product.name" 
                                    type="text"
                                    readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Цена" 
                                    :prefix="order.currency"
                                    v-model.number="price"
                                    type="text"
                                    @change="value_changed = true"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Количество товара" 
                                    v-model.number="quantity"
                                    type="text"
                                    @change="value_changed = true"
                                    :rules="[rules.required, rules.min]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" 
                    :disabled="!value_changed ||Boolean(error)"
                    @click="editProduct">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['product', 'order'],
    data() {
        return {
            dialog: false,
            valid: false,
            value_changed: false,
            error: '',
            rules: {
                required: value => !!value || 'обязательное поле',
                min: v => v >= 1 || 'должен быть не менее 1', 
            },
            //props
            price: this.product.price,
            quantity: this.product.quantity,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        
    },
    methods: {
        closeDialog() {
            //this.$refs.form.reset();
            this.price = this.product.price;
            this.quantity = this.product.quantity;
            
            this.dialog = false;
            this.value_changed = false;
        },
        async editProduct() {
            if (this.$refs.form.validate()) {
                
                if (this.price == this.product.price ||
                    this.quantity == this.product.quantity) {
                    
                    this.error = 'Не было никаких изменений!'
                    this.value_changed = false;
                }
                const product = {... this.product, 
                    price: this.price,  quantity: this.quantity
                }

                const products = this.order.products.filter(pro => pro.id !== product.id);
                products.push(product);

                const obj = { id: this.order.id, products }
                //console.log(obj)
                try {
                    await this.$store.dispatch('editOrder', obj);
                    this.value_changed = false;
                    this.dialog = false;
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    },

}
</script>