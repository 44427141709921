<template>
    <v-dialog v-model="dialog" scrollable width="450px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" class="text-lowercase mr-4" dark small v-on="on">добавить отзыв</v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Новый отзыв
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field color="primary" dense
                                    label="имя автора"
                                    v-model="author" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select :items="items" dense label="Пол" v-model="sex">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field color="primary" dense
                                    label="рейтинг (1-5)"
                                    v-model.number="rating"
                                    type="text"
                                    :rules="[rules.required, rules.min, rules.max]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field color="primary" dense
                                    label="ссылка в facebook"
                                    v-model="facebookUrl" type="text">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined 
                                    label="текст (английский)" auto-grou
                                    v-model.lazy="text_en" 
                                    :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined 
                                    label="текст (румынский)" auto-grou
                                    v-model.lazy="text_ro" 
                                    :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined 
                                    label="текст (русский)" auto-grou
                                    v-model.lazy="text_ru" 
                                    :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="Boolean(error)"
                    @click="addReview">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            items: ['женщина', 'мужчина'],
            rules: {
                required: value => !!value || 'обязательное поле',
                min: value => value > 1 || 'рейтинг не может быть меньше 1',
                max: value => value < 6 || 'рейтинг не может быть больше 5',
                maxSize: value => {
                    return !value || !value.length || value[0].size < 1000000 || 'Фото не может быть более 1 MB!'
                },
            },
            //props
            sex: 'женщина',
            author: '',
            text_en: '',
            text_ro: '',
            text_ru: '',
            facebookUrl: '',
            rating: 5,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.dialog = false;
        },
        async addReview() {
            if (this.$refs.form.validate()) {

                let photo = 'https://firebasestorage.googleapis.com/v0/b/momykeep-f0708.appspot.com/o/man.png?alt=media&token=32d0ef50-4224-4923-80cd-ffba21437462';

                if (this.sex == 'женщина') {
                    photo = 'https://firebasestorage.googleapis.com/v0/b/momykeep-f0708.appspot.com/o/woman.png?alt=media&token=600bf1f6-c34f-4c87-ae50-dadd812e0125';
                } 
                const review = {
                    author: this.author,
                    text_en: this.text_en,
                    text_ro: this.text_ro,
                    text_ru: this.text_ru,
                    photo: photo,
                    rating: this.rating,
                }

                if (this.facebookUrl) {
                    review.facebookUrl = this.facebookUrl;
                }
                //console.log(review)
                try {
                    await this.$store.dispatch('addReview', review);
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>