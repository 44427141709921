<template>
    <v-dialog v-model="dialog" scrollable width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary"
                class="text-lowercase mr-4" 
                dark small v-on="on">
                запустить акцию
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Объявление акции (скидки)
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row>
                            <!--title-->
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" dense
                                    label="Название акции (румынский)" 
                                    v-model="title_ro" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" dense
                                    label="Название акции (русский)" 
                                    v-model="title_ru" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" dense
                                    label="Название акции (английский)" 
                                    v-model="title_en" 
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <!--description-->
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" dense
                                    label="Описание акции (румынский)" 
                                    v-model="text_ro" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" dense
                                    label="Описание акции (русский)" 
                                    v-model="text_ru" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" dense
                                    label="Описание акции (английский)" 
                                    v-model="text_en" 
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" sm="3">
                                <v-text-field color="primary" dense
                                    label="скидка" 
                                    v-model.number="discount"
                                    suffix="%"
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                    
                            <v-col cols="12" sm="9" md="10">
                                <v-file-input show-size dense
                                    v-model="image" 
                                    accept="image/png, image/jpeg"
                                    label="Изображение размером (1:3) 450 x 675 пикселов!"
                                    :rules="[rules.required, rules.maxSize]">
                                </v-file-input>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu ref="menu1"
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :nudge-right="20"
                                    :return-value.sync="date_start"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="220px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense
                                            v-model="date_start"
                                            label="Начало акции"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_start" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text small color="primary"
                                            class="text-lowercase" 
                                            @click="menu1 = false">
                                            Отмена
                                        </v-btn>
                                        <v-btn small color="primary"
                                            class="text-lowercase"
                                            @click="$refs.menu1.save(date_start)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="20"
                                    :return-value.sync="date_end"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="250px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense
                                            v-model="date_end"
                                            label="Конец акции"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_end" 
                                        no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text small class="text-lowercase"
                                            color="primary" 
                                            @click="menu2 = false">Отмена</v-btn>
                                        <v-btn class="text-lowercase" 
                                            small color="primary" 
                                            @click="$refs.menu2.save(date_end)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="!valid || Boolean(error)"
                    @click="addAction">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            rules: {
                required: value => !!value || 'required field',
                maxSize: value => {
                    return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
                },
            },
            //props
            title_en: '',
            title_ru: '',
            title_ro: '',
            text_en: '',
            text_ru: '',
            text_ro: '',
            discount: 10,
            date_start: new Date().toISOString().substr(0, 10),
            date_end: new Date().toISOString().substr(0, 10), 
            menu1: false,
            modal: false,
            menu2: false,
            image: []
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.dialog = false;
        },
        async addAction() {
            if (this.$refs.form.validate()) {
                const action = {
                    title_en: this.title_en,
                    title_ru: this.title_ru,
                    title_ro: this.title_ro,
                    text_en: this.text_en,
                    text_ru: this.text_ru,
                    text_ro: this.text_ro,
                    discount: this.discount,
                    date_start: new Date(this.date_start),
                    date_end: new Date(this.date_end),
                    image: this.image
                }
                console.log(action)
                try {
                    await this.$store.dispatch('addAction', action);
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>