<template>
    <v-dialog v-model="dialog" scrollable width="350px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" icon small>
                <v-icon small>mdi-delete</v-icon>    
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="error--text subtitle-2 pt-6">
                Вы действительно хотите далить акцию?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" 
                    :disabled="Boolean(error)"
                    @click="deleteAction">Удалить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['action'],
    data() {
        return {
            dialog: false,
            error: '',
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        async deleteAction() {
            
            const path = `actions/action`
            try {
                await this.$store.dispatch('deleteAction', this.action.id); //delete from db
                await this.$store.dispatch('deleteImage', path);// delete image from firestore
            }
            catch (error) {
                await this.$store.dispatch('setError', error.message)
            }
        }
    }
}
</script>