<template>
    <v-dialog v-model="dialog" scrollable width="450px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class="text-lowercase" color="primary">
                <v-icon>mdi-upload</v-icon>
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Загрузка изображения товара
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="5" sm="5">
                                <v-text-field color="primary" label="Код товара" v-model="id" type="text" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="7" sm="7">
                                <v-text-field color="primary" label="Название товара" v-model="name" type="text"
                                    readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                                <v-file-input show-size v-model="image" accept="image/png, image/jpeg" label="Изображение"
                                    :rules="[rules.required, rules.maxSize]">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="Boolean(error)"
                    @click="uploadImage">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: ['product'],
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            rules: {
                required: value => !!value || 'обязательное поле',
                maxSize: value => {
                    return !value || !value.length || value[0].size < 1000000 || 'Размер изображения не может превышать 1 MB!'
                },
            },
            //props
            id: this.product.id,
            name: this.product.name_ru,
            image: [],
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.id = this.product.id;
            this.name = this.product.name_ru;
            this.dialog = false;
        },
        async uploadImage() {
            if (this.$refs.form.validate()) {
                console.log(this.image)
                try {
                    //await this.$store.dispatch('uploadImage', { file: this.image, path });
                    await this.$store.dispatch('addProductImage', { product: this.product, image: this.image });
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>