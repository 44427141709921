<template>
    <v-container fluid>
        <div v-if="reviews.length">
            <!--add view-->
            <v-row class="my-2" no-gutters>
                <add-review></add-review>
            </v-row>
            <!--- header -->
            <v-row class="subtitle-2 font-weight-bold mb-2">
                <v-col v-for="(header, idx) in headers" :key="idx" :class="header.class">
                    {{ header.title }}
                </v-col>
            </v-row>
            <!--- table row -->
            <div v-for="(review, i) in paginatedReviews" :key="review.id">
                <v-row class="body-2" no-gutters>
                    <v-col class="text-left">{{ i + 1 }}</v-col>
                    <v-col class="text-left">{{ dateLocale(review.date) }}</v-col>
                    <v-col class="text-left">{{ review.author }}</v-col>
                    <v-col class="text-left">{{ review[`text_${locale.split('-')[0]}`] }}</v-col>
                    <v-col class="text-center">{{ review.rating }}</v-col>
                    <v-col class="text-center"><!--actions-->
                        <delete-review :review="review"></delete-review>
                    </v-col>
                
                </v-row>
                <v-divider class="my-2"></v-divider>
            </div>
            
            <div class="text-center mt-4">
                <v-pagination v-model="page" :length="pages">
                </v-pagination>
            </div>
        </div>
        <v-row v-else>пока нет отзывов</v-row>
    </v-container>
</template>
<script>
import addReview from './reviews/AddReview.vue';
import deleteReview from './reviews/DeleteReview.vue'

export default {
    components: { addReview, deleteReview },
    data() {
        return {
            headers: [
                {title: 'номер', class: 'text-left'},
                { title: 'дата', class: 'text-left' },
                { title: 'автор', class: 'text-left' },
                { title: 'текст', class: 'text-left' }, 
                { title: 'рейтинг', class: 'text-center' },
                { title: 'удалить', class: 'text-center' }
            ],
            page: 1,//for pagination
            NUM_OF_REVIEWS: 6,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        locale() {
            return this.$store.getters.locale;
        },
        reviews() {
            return this.$store.getters.reviews;
        },
        startIndex() {
            return this.NUM_OF_REVIEWS * (this.page - 1);
        },
        endIndex() {
            return this.NUM_OF_REVIEWS * this.page;
        },
        pages() {
            const nums = this.reviews?.length;
            const pages = Math.ceil(nums / this.NUM_OF_REVIEWS);
            return pages > 0 ? pages : 1;
        },
        paginatedReviews() {
            return this.reviews?.slice(this.startIndex, this.endIndex);
        },
    },
    methods: {
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleDateString(this.locale);
            }
            return 'no date'
        },

    },
}
</script>
