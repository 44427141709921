<template>
    <v-container fluid>
        <div v-if="products.length">
            <!--add product-->
            <v-row class="my-2" no-gutters>
                <add-product></add-product>
            </v-row>
            <!--filter-->
            <v-row class="my-2" no-gutters>
                <span class="caption font-weight-bold">фильтровать товары по категории:</span>
                <v-btn-toggle v-model="selected" class="ml-2">
                    <v-btn v-for="item in items"
                        :key="item" outlined small 
                        color="primary" 
                        class="text-lowercase"
                        :value="item">
                        {{ item }}
                    </v-btn>
                </v-btn-toggle>
            </v-row>

            <!--- header -->
            <v-row class="subtitle-2 font-weight-bold mb-2">
                <v-col v-for="(header, idx) in headers" :key="idx" class="text-left">
                    {{ header }}
                </v-col>
            </v-row>
            <v-expansion-panels focusable>
                <v-expansion-panel v-for="prod in paginatedProducts" :key="prod.id">
                    <v-expansion-panel-header>
                        <!--- table row -->
                        <v-row class="body-2" no-gutters>
                            <v-col class="text-left">{{ prod.id }}</v-col>
                            <v-col class="text-left">{{ prod.category }}</v-col>
                            <v-col class="text-left">{{ prod.name_ro }}</v-col>
                            <v-col class="text-left">{{ prod.name_ru }}</v-col>
                            <v-col class="text-left">{{ prod.name_en }}</v-col>
                            <v-col class="text-center">{{ prod.price.toLocaleString('en-US') }}</v-col>
                            <v-col class="text-center">{{ prod.price_RON }}</v-col>
                            <v-col class="text-center">{{ prod.price_USD }}</v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2">
                        <!--- description -->
                        <v-row class="subtitle-2 font-weight-bold">
                            <v-col v-for="(header, idx) in headers2" :key="idx" class="text-left">
                                {{ header }}
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="text-left">{{ prod.description_ro }}</v-col>
                            <v-col class="text-left">{{ prod.description_ru }}</v-col>
                            <v-col class="text-left">{{ prod.description_en }}</v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row justify="end" class="ma-2">
                            <edit-product :product="prod"></edit-product>
                            <delete-product :product="prod"></delete-product>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="text-center mt-4">
                <v-pagination v-model="page" :length="pages">
                </v-pagination>
            </div>
        </div>
        <v-row v-else>пока нет товаров</v-row>
    </v-container>
</template>
<script>
import AddProduct from './products/AddProduct';
import EditProduct from './products/EditProduct.vue';
import DeleteProduct from './products/DeleteProduct.vue';
export default {
    components: { AddProduct, EditProduct, DeleteProduct },
    data() {
        return {
            headers: ['код', 'категория', 'название (румынский)', 'название (русский)', 'название (английский)',
                'цена (MDL)', 'цена (RON)', 'цена (USD)'],
            headers2: ['описание на румынском', 'описание на русском', 'описание на английском'],
            items: ['newborn', 'premature', 'sets'],
            selected: 'sets',
            page: 1,//for pagination
            NUM_OF_PRODS: 5,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        products() {
            return this.$store.getters.products;
        },
        startIndex() {
            return this.NUM_OF_PRODS * (this.page - 1);
        },
        endIndex() {
            return this.NUM_OF_PRODS * this.page;
        },
        pages() {
            const nums = this.filteredProducts.length;
            const pages = Math.ceil(nums / this.NUM_OF_PRODS);

            return pages > 0 ? pages : 1;
        },
        filteredProducts() {
            if (this.selected) {
                return this.products.filter((prod) => prod.category == this.selected);
            }
            return this.products;
        },
        paginatedProducts() {
            return this.filteredProducts.slice(this.startIndex, this.endIndex);
        },
    },
    watch: {
        paginatedProducts() {
            if (this.paginatedProducts.length === 0 && this.page > 1) {
                this.page -= 1;
            }
        },
    },
    methods: {
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleString(this.locale, { hour12: false });
            }
            return 'no date'
        },

    },
}
</script>