<template>
    <v-container fluid>
        <div v-if="products.length">
            <!--filter products-->
            <v-row class="my-2" no-gutters>
                <span class="caption font-weight-bold">фильтровать товары по категории:</span>
                <v-btn-toggle v-model="selected" class="ml-2">
                    <v-btn v-for="item in items"
                        :key="item" outlined small 
                        color="primary" 
                        class="text-lowercase"
                        :value="item">
                        {{ item }}
                    </v-btn>
                </v-btn-toggle>
            </v-row>
            <!--- header -->
            <v-row class="subtitle-2 font-weight-bold mb-2">
                <v-col v-for="(header, idx) in headers" :key="idx" class="text-left">
                    {{ header }}
                </v-col>
                <v-col v-for="i in 6" :key="`img${i}`">изображение {{ i }}</v-col>
            </v-row>

            <!--- table row -->
            <div v-for="prod in paginatedProducts" :key="prod.id"> 
                <div v-if="prod.images">
                    <v-row class="body-2" no-gutters>
                        <v-col class="text-left">{{ prod.id }}</v-col>
                        <v-col class="text-left">{{ prod.category }}</v-col>
                        <v-col class="text-left">{{ prod.name_ru }}</v-col>
                        <!-- images max 6 -->
                        <v-col v-for="(image, i) in prod.images"
                            :key="i"
                            class="text-truncate">
                            <!-- product image editing-->
                            <delete-product-image :product="prod" :image="image"></delete-product-image>
                        </v-col>
                        <v-col v-for="i in (countOfImages(prod.images.length))" :key="`newImg${i}`" class="text-center">
                            <upload-image :product="prod"></upload-image>
                        </v-col>
                    </v-row>     
                </div>
                <div v-else>
                    <v-row class="body-2" no-gutters>
                        <v-col class="text-left">{{ prod.id }}</v-col>
                        <v-col class="text-left">{{ prod.category }}</v-col>
                        <v-col class="text-left">{{ prod.name_ru }}</v-col>
                        <v-col v-for="i in 6" :key="`newImg${i}`" class="text-center">
                            <upload-image :product="prod"></upload-image>
                        </v-col>
                    </v-row>
                </div>
                
                <v-divider></v-divider>
            </div>
            <div class="text-center mt-8">
                <v-pagination v-model="page" :length="pages">
                </v-pagination>
            </div>
        </div>
        <v-row v-else>пока нет товаров</v-row>
    </v-container>
</template>
<script>
import DeleteProductImage from './products/DeleteProductImage.vue';
import UploadImage from './products/UploadProductImage.vue';

export default {
    components: { DeleteProductImage, UploadImage },
    data() {
        return {
            headers: ['код товара', 'категория', 'название'],
            page: 1,//for pagination
            NUM_OF_PRODS: 10,
            items: ['newborn', 'premature', 'sets'],
            selected: 'sets',
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        products() {
            return this.$store.getters.products;
        },
        startIndex() {
            return this.NUM_OF_PRODS * (this.page - 1);
        },
        endIndex() {
            return this.NUM_OF_PRODS * this.page;
        },
        pages() {
            const nums = this.filteredProducts?.length;
            const pages = Math.ceil(nums / this.NUM_OF_PRODS);

            return pages > 0 ? pages : 1;
        },
        filteredProducts() {
            if (this.selected) {
                return this.products?.filter((prod) => prod.category == this.selected);
            }
            return this.products;
        },
        paginatedProducts() {
            return this.filteredProducts?.slice(this.startIndex, this.endIndex);
        },
    },
    watch: {
        paginatedProducts() {
            if (this.paginatedProducts.length === 0 && this.page > 1) {
                this.page -= 1;
            }
        },
    },
    methods: {
        countOfImages(num) {
            return 6 - num
        },

    },
}
</script>