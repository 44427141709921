<template>
    <v-container fluid>
        <div v-if="orders.length">
            <!--filter-->
            <v-row class="my-2" no-gutters>
                <span class="caption font-weight-bold">фильтровать заказы по статусу:</span>
                <v-btn-toggle v-model="selected" class="ml-2">
                    <v-btn v-for="item in items" :key="item" outlined small color="primary" class="text-lowercase"
                        :value="item">
                        {{ item }}
                    </v-btn>
                </v-btn-toggle>
            </v-row>
            <!--- header -->
            <v-row class="subtitle-2 font-weight-bold mb-2 mx-1">
                <v-col v-for="(header, idx) in headers"
                    :cols="header.cols"
                    :key="idx" 
                    class="text-left">
                    {{ header.title }}
                </v-col>
            </v-row>
            <v-expansion-panels focusable>
                <v-expansion-panel v-for="(order, idx) in paginatedOrders" :key="order.id">
                    <v-expansion-panel-header>
                        <!--- table row -->
                        <v-row class="body-2">
                            <v-col cols="1" class="text-left">№ {{ idx + 1 }}</v-col>
                            <v-col cols="1" class="text-left">{{ order.status }}</v-col>
                            <v-col cols="1" class="text-left">{{ dateLocale(order.orderDate) }}</v-col>
                            <v-col cols="2" class="text-left">{{ order.userPhone }} {{ order.userEmail }}</v-col>
                            <v-col cols="1" class="text-left">{{ order.userFirstName}} {{ order.userLastName }}</v-col>
                            <v-col cols="2" class="text-left">{{ order.country }}, {{ order.city }}, {{ order.address }}</v-col>
                            <v-col cols="1" class="text-left">{{ order.delivery }}</v-col>
                            <v-col cols="1" class="text-left">{{ order.discount }}</v-col>
                            <v-col cols="2" class="text-left">{{ order.comments }}</v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2">
                        <v-divider></v-divider>
                        <!--- header -->
                        <v-row class="font-weight-bold my-1">
                            <v-col v-for="(header, idx) in headers2" :key="idx" class="text-left">
                                {{ header }}
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>
                        <!--- table products -->
                        <v-row v-for="prod in order.products" :key="prod.id" no-gutters>
                            <v-col class="text-left">{{ prod.id }}</v-col>
                            <v-col class="text-left">{{ prod.name }}</v-col>
                            <v-col class="text-left">{{ prod.price.toLocaleString('en-US') }} {{ order.currency }}</v-col>
                            <v-col class="text-left">{{ prod.quantity }} </v-col>
                            <v-col class="text-left">{{ (prod.price * prod.quantity).toLocaleString('en-US') }} {{ order.currency }}</v-col>
                            <v-col class="text-center">
                                <edit-product
                                    :product="prod"
                                    :order="order">
                                </edit-product>
                                <span class="mx-4">
                                    <delete-product
                                        :product="prod"
                                        :order="order">
                                    </delete-product>
                                </span>
                                
                                <add-product
                                    :order="order">
                                </add-product>
                            </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row justify="start" class="my-2">
                            <edit-order :order="order"></edit-order>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="my-2" justify="end">
                <v-btn 
                    class="mx-auto text-lowercase"
                    color="primary"
                    @click="exportData(orders, 'orders', 'xls')"
                    outlined>
                    скачать данные в Excel
                </v-btn>
            </v-row>
            <div class="text-center mt-4">
                <v-pagination v-model="page" :length="pages">
                </v-pagination>
            </div>
        </div>
        <v-row v-else>Заказов пока нет</v-row>
    </v-container>
</template>
<script>
import { excelParser } from '../../plugins/excel-parser';

import EditOrder from '../dashboard/orders/EditOrder.vue';
import EditProduct from './orders/EditProduct.vue';
import DeleteProduct from './orders/DeleteOrderProduct.vue';
import AddProduct from './orders/AddOrderProduct.vue';

export default {
    data() {
        return {
            headers: [
                { title:'номер заказа', cols: 1 },
                {title: 'статус заказа', cols: 1 },
                {title: 'дата', cols: 1},
                {title: 'телефон и email клиента', cols: 2},
                {title: 'имя клиента', cols: 1},
                {title: 'адрес доставки', cols: 2},
                {title: 'доставка (MDL)', cols: 1 },
                {title: 'скидка (MDL)', cols: 1 },
                {title: 'комментарий', cols: 2}],
            headers2: ['код', 'наименование', 'цена', 'количество', 'сумма', 'редактировать / удалить / добавить'],
            items: ['получен',
                'одобрен',
                'отклонён',
                'укомплектован',
                'отправлен',
                'доставлен',
                'оплачен',
                'возврат',
                'утерян'],
            selected: null,
            page: 1,//for pagination
            NUM_OF_ORDERS: 6,
            readonly: false,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        orders() {
            return this.$store.getters.orders;
        },
        startIndex() {
            return this.NUM_OF_ORDERS * (this.page - 1);
        },
        endIndex() {
            return this.NUM_OF_ORDERS * this.page;
        },
        pages() {
            const numOrds = this.filteredOrders?.length;
            const pages = Math.ceil(numOrds / this.NUM_OF_ORDERS);
            return pages > 0 ? pages : 1;
        },
        filteredOrders() {
            if (this.selected) {
                return this.orders.filter((order) => order.status == this.selected);
            }
            return this.orders;
        },
        paginatedOrders() {
            return this.filteredOrders?.slice(this.startIndex, this.endIndex);
        },
    },
    watch: {
        paginatedOrders() {
            if (this.paginatedOrders.length === 0 && this.page > 1) {
                this.page -= 1;
            }
        },
    },
    methods: {
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleDateString(this.locale);
            }
            return 'no date'
        },
        //parsing to Excel
        exportData(data, filename, ext) {
            excelParser().exportDataFromJSON(data, filename, ext);
        }

    },
    components: { EditOrder, EditProduct, DeleteProduct, AddProduct }
}
</script>