<template>
    <v-dialog v-model="dialog" scrollable width="350px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="text-lowercase mr-4" dark small color="primary">
                удалить товар
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="error--text subtitle-2 pt-6">
                Удалить товар?
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form>
                        <v-row no-gutters>
                            <v-col cols="12" md="12" sm="12">
                                <v-text-field color="primary" label="Код товара" v-model="id" type="text" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                                <v-text-field color="primary" label="Название товара" v-model="title" type="text" readonly>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="Boolean(error)"
                    @click="deleteProduct">Удалить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['product'],
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            rules: {
                required: value => !!value || 'required field',
            },
            //props
            id: this.product.id,
            title: this.product.name_ru,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.id = this.product.id;
            this.title = this.product.name_ru;
            this.dialog = false;
        },
        async deleteProduct() {
            if (this.product.id) {
                const id = this.product.id;
                try { 
                    // delete product images from firestore
                    this.product.images.forEach(image => this.deleteProductImage(this.product, image))
                    await this.$store.dispatch('deleteProduct', id); //delete from db
                    this.closeDialog();
                }
                catch (error) {
                    await this.$store.dispatch('setError', error.message)
                }
            }
        },
        async deleteProductImage(product, image) {
            const path = `products/${product.category}/${image.name}`
            try {
                await this.$store.dispatch('deleteImage', path);
            }
            catch (error) {
                await this.$store.dispatch('setError', error.message)   
            }
        }
    }
}
</script>