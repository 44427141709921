<template>
    <v-dialog v-model="dialog" scrollable width="600px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" class="text-lowercase mr-4" dark small v-on="on">добавить статью</v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Новая статья для блога
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row no-gutters>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" label="Код статьи"
                                    v-model="id" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" label="название статьи (ru)" v-model="title_ru" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field color="primary" label="название статьи (ro)" v-model="title_ro" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field color="primary" label="название статьи (en)" v-model="title_en" type="text"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-file-input show-size v-model="image" accept="image/png, image/jpeg" label="Изображение размером 800 x 533 пикселов!"
                                    :rules="[rules.required, rules.maxSize]">
                                </v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-ru" label="текст статьи на русском" auto-grou
                                    v-model.lazy="text_ru" :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-ro" label="текст статьи на румынском" auto-grou
                                    v-model.lazy="text_ro" :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-en" label="текст статьи на английском" auto-grou
                                    v-model.lazy="text_en" :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="Boolean(error)"
                    @click="addArticle">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            rules: {
                required: value => !!value || 'required field',
                maxSize: value => {
                    return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
                },
            },
            //props
            id: '',
            title_ru: '',
            title_ro: '',
            title_en: '',
            image: [],
            text_ro: '',
            text_ru: '',
            text_en: '',
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.dialog = false;
        },
        async addArticle() {
            if (this.$refs.form.validate()) {
                const article = {
                    id: this.id,
                    title_en: this.title_en,
                    title_ro: this.title_ro,
                    title_ru: this.title_ru,
                    image: this.image,
                    text_en: this.text_en,
                    text_ro: this.text_ro,
                    text_ru: this.text_ru,
                }
                //console.log(article)
                try {
                    await this.$store.dispatch('addArticle', article);
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>