<template>
    <v-dialog v-model="dialog" scrollable width="600px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" class="text-lowercase mr-4" dark small v-on="on">
                редактировать статью
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Редактирование статьи для блога
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row no-gutters>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" label="Код статьи" v-model="article.id" type="text"
                                    readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field color="primary" label="название статьи (ru)" v-model="title_ru" type="text"
                                    @change="value_changed = true"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field color="primary" label="название статьи (ro)" v-model="title_ro" type="text"
                                    @change="value_changed = true"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field color="primary" label="название статьи (en)" v-model="title_en" type="text"
                                    @change="value_changed = true"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-ru" label="текст статьи на русском" auto-grou
                                    v-model.lazy="text_ru"
                                    @change="value_changed = true"
                                    :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-ro" label="текст статьи на румынском" auto-grou
                                    v-model.lazy="text_ro"
                                    @change="value_changed = true"
                                    :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea outlined name="ta-en" label="текст статьи на английском" auto-grou
                                    v-model.lazy="text_en"
                                    @change="value_changed = true"
                                    :rules="[rules.required]">
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize"
                    :disabled="!value_changed || Boolean(error)"
                    @click="editArticle">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['article'],
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            value_changed: false,
            rules: {
                required: value => !!value || 'required field',
            },
            //props
            title_ru: this.article.title_ru,
            title_ro: this.article.title_ro,
            title_en: this.article.title_en,
            text_ro: this.article.text_ro,
            text_ru: this.article.text_ru,
            text_en: this.article.text_en,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
    },
    methods: {
        closeDialog() {
            //this.$refs.form.reset();
            this.title_ru = this.article.title_ru;
            this.title_ro = this.article.title_ro;
            this.title_en = this.article.title_en;
            this.text_ro = this.article.text_ro;
            this.text_ru = this.article.text_ru;
            this.text_en = this.article.text_en;
            this.value_changed = false;
            this.dialog = false;
        },
        async editArticle() {
            if (this.$refs.form.validate()) {
                
                const article = {
                    title_en: this.title_en,
                    title_ro: this.title_ro,
                    title_ru: this.title_ru,
                    //image: this.article.image,
                    text_en: this.text_en,
                    text_ro: this.text_ro,
                    text_ru: this.text_ru,
                }
                //console.log(article)
                try {
                    await this.$store.dispatch('editArticle', {id: this.article.id, article: article});
                    
                    this.value_changed = false;
                    this.dialog = false;
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>