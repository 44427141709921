<template>
    <v-dialog v-model="dialog" scrollable width="350px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="text-lowercase mr-4" dark small color="primary">
                удалить отзыв
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="error--text subtitle-2 pt-6">
                Удалить отзыв?
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form>
                        <v-row no-gutters>
                            <v-col cols="12" md="12" sm="12">
                                <v-text-field color="primary"
                                    label=""
                                    v-model="review.author" 
                                    type="text" readonly>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                                <v-textarea outlined 
                                    label="текст" auto-grou
                                    v-model.lazy="review[`text_${locale.split('-')[0]}`]"
                                    readonly>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="Boolean(error)"
                    @click="deleteReview">Удалить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['review'],
    data() {
        return {
            dialog: false,
            error: '',
            //props
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        locale() {
            return this.$store.getters.locale;
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        async deleteReview() {
            if (this.review.id) {
                //const path = `reviews/${this.review.photo.name}`
                try {
                    await this.$store.dispatch('deleteReview', this.review.id); //delete from db
                    //await this.$store.dispatch('deleteImage', path);// delete image from firestore
                }
                catch (error) {
                    await this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>