<template>
    <v-dialog v-model="dialog" scrollable width="700px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" class="text-lowercase mr-4" dark small v-on="on">
                Редактирование заказа
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6">
                Редактирование заказа  
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-select :items="items" dense 
                                    label="Статус заказа" 
                                    v-model="status">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Имя клиента"
                                    v-model="firstName" 
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>    
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Фамилия клиента"
                                    v-model="lastName" 
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>    
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Телефон клиента"
                                    v-model="phone" 
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>    
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select dense 
                                    :menu-props="{ top: true, offsetY: true }"
                                    :label="'Country' | localize"
                                    v-model="country"
                                    :items="countries"
                                    :rules="[rules.required]">
                                </v-select>    
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Город"
                                    v-model="city" 
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>    
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Адрес доставки"
                                    v-model="address" 
                                    type="text"
                                    :rules="[rules.required]">
                                </v-text-field>    
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Доставка"
                                    v-model.number="delivery" 
                                    type="text">
                                </v-text-field>    
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field dense
                                    color="primary" 
                                    label="Скидка"
                                    v-model.number="discount" 
                                    type="text">
                                </v-text-field>    
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" 
                    :disabled="!valid || Boolean(error)"
                    @click="editOrder">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>
import localizeFilter from '../../../filters/localize.filter';
export default {
    props: ['order'],
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            items: ['получен',
                'одобрен',
                'отклонён',
                'укомплектован',
                'отправлен',
                'доставлен',
                'оплачен',
                'возврат',
                'утерян'],
            rules: {
                required: value => !!value || 'required field',
            },
            //props
            status: this.order.status,
            firstName: this.order.userFirstName,
            lastName: this.order.userLastName,
            phone: this.order.userPhone,
            country: this.order.country,
            city: this.order.city,
            address: this.order.address,
            delivery: this.order.delivery,
            discount: this.order.discount
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        countries() {
            return [localizeFilter('Moldova'), localizeFilter('Romania')];
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.status = this.order.status;
            this.firstName = this.order.userFirstName;
            this.lastName = this.order.userLastName;
            this.phone = this.order.userPhone;
            this.country = this.order.country;
            this.city = this.order.city;
            this.address =  this.order.address;
            this.delivery = this.order.delivery;
            this.discount = this.order.discount;
        },
        async editOrder() {
            if (this.$refs.form.validate()) {
                const payload = { 
                    id: this.order.id,
                    status: this.status,
                    userFirstName: this.firstName,
                    userLastName: this.lastName,
                    userPhone: this.phone,
                    country: this.country,
                    city: this.city,
                    address: this.address,
                    delivery: this.delivery,
                    discount: this.discount
                }
                
                try {
                    await this.$store.dispatch('editOrder', payload);
                    this.dialog = false;
                }
                catch (error) {
                    this.$store.dispatch('setError', error.message)
                }
            }
        }
    }
}
</script>