<template>
    <v-dialog v-model="dialog" scrollable width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" icon small v-on="on">
                <v-icon small>mdi-cart-plus</v-icon>
            </v-btn>
        </template>
        <v-card v-if="!loading">
            <v-card-title class="subtitle-2 pt-6 primary--text">
                Добавление продукта к заказу
            </v-card-title>
            <v-card-text class="black--text">
                <v-container grid-list-md>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="8" sm="8" class="text-left">
                                <v-select dense color="primary" 
                                    label="Выберите товар" 
                                    v-model="code"
                                    :items="items"
                                    :rules="[rules.required]">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-text-field dense color="primary" label="Количество товара" 
                                    v-model.number="quantity"
                                    type="text" 
                                    :rules="[rules.required, rules.min]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="text-capitalize" @click="closeDialog">
                    Отмена
                </v-btn>
                <v-btn color="primary" text class="text-capitalize" :disabled="!valid || Boolean(error)"
                    @click="addProduct">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="text-xs-center">
            <loader></loader>
        </div>
    </v-dialog>
</template>

<script>

export default {
    props: ['order'],
    data() {
        return {
            dialog: false,
            valid: false,
            error: '',
            rules: {
                required: value => !!value || 'обязательное поле',
                min: v => v >= 1 || 'должен быть не менее 1',
            },
            //props
            code: '',
            quantity: 1,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        products() {
            return this.$store.getters.products;
        },
        items() {
            const its = this.products?.map(prod => {
                return {
                    value: prod.id,
                    text: prod.id  + ' - ' + prod.name_ru,
                }
            })
            return its;
        }
    },
    methods: {
        getPrice(item) {
            if (this.order.currency === 'MLD') {
                return item.price;
            }
            const key = `price_${this.order.currency}`;
            return item[key];
        },
        closeDialog() {
            //this.$refs.form.reset();
            this.quantity = 1;
            this.code = '';

            this.dialog = false;   
        },
        async addProduct() {
            if (this.$refs.form.validate()) {

                const product = this.products.find(prod => prod.id == this.code)

                if (product) {
                    const products = this.order.products;

                    products.push({
                        id: product.id,
                        name: product.name_ru,
                        price: this.getPrice(product),
                        quantity: this.quantity
                    });

                    const obj = { id: this.order.id, products }
                    
                    try {
                        await this.$store.dispatch('editOrder', obj);
                        this.dialog = false;
                    }
                    catch (error) {
                        this.$store.dispatch('setError', error.message)
                    }
                } 
            }
        }
    },

}
</script>