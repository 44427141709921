<template>
    <v-container fluid>
        <div v-if="users.length">
            <!--- header -->
            <v-row class="subtitle-2 font-weight-bold mb-2">
                <v-col v-for="(header, idx) in headers" :key="idx" class="text-left">
                    {{ header }}
                </v-col>
            </v-row>

            <div v-for="(user, i) in paginatedUsers" :key="user.id">
                <!--- table row -->
                <v-row class="body-2">
                    <v-col class="text-left">{{ i + 1 }}</v-col>
                    <v-col class="text-left">{{ user.role }}</v-col>
                    <v-col class="text-left">{{ user.firstName }}</v-col>
                    <v-col class="text-left">{{ user.lastName }}</v-col>
                    <v-col class="text-left">{{ user.email }}</v-col>
                    <v-col class="text-left">{{ user.phone }}</v-col>
                    <v-col class="text-left">{{ user.country }} {{ user.city }}  {{  user.address }}</v-col>
                    <v-col class="text-center">
                        <edit-user :user="user"></edit-user>
                    </v-col>
                </v-row>
            </div>
            <div class="text-center mt-4">
                <v-pagination v-model="page" :length="pages">
                </v-pagination>
            </div>
        </div>
        <v-row v-else>no users yet</v-row>
    </v-container>
</template>
<script>
import EditUser from '../dashboard/users/EditUser.vue';
export default {
    components: { EditUser },
    data() {
        return {
            headers: ['№', 'роль','имя', 'фамилия', 'email', 'телефон', 'адресс', 'редактировать'],
            page: 1,//for pagination
            NUM_OF_USERS: 10,
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        users() {
            return this.$store.getters.users;
        },
        startIndex() {
            return this.NUM_OF_USERS * (this.page - 1);
        },
        endIndex() {
            return this.NUM_OF_USERS * this.page;
        },
        pages() {
            const nums = this.users?.length;
            const pages = Math.ceil(nums / this.NUM_OF_USERS);
            return pages > 0 ? pages : 1;
        },
        paginatedUsers() {
            return this.users?.slice(this.startIndex, this.endIndex);
        },
    },
    methods: {

    },
}
</script>