<template>
    <v-container fluid>

        <!--add article-->
        <v-row class="my-2" no-gutters>
            <add-action></add-action>
        </v-row>

        <div v-if="action">
            <!--- header -->
            <v-row class="subtitle-2 font-weight-bold mb-2">
                <v-col v-for="(header, idx) in headers" :key="idx" class="text-left">
                    {{ header }}
                </v-col>
            </v-row>

            <!--- table row -->
            <v-row class="body-2">
                <v-col class="text-left">{{ action.title_en }}</v-col>
                <v-col class="text-left">{{ action.title_ru }}</v-col>
                <v-col class="text-left">{{ action.title_ro }}</v-col>
                <v-col class="text-left">{{ action.text_en }}</v-col>
                <v-col class="text-left">{{ action.text_ru }}</v-col>
                <v-col class="text-left">{{ action.text_ro }}</v-col>
                <v-col class="text-left">{{ dateLocale(action.date_start) }}</v-col>
                <v-col class="text-left">{{ dateLocale(action.date_end) }}</v-col>
                <v-col class="text-left">{{ action.discount }}</v-col>
                <v-col>
                    <delete-action :action="action"></delete-action>
                </v-col>
            </v-row>
            <!-- </div>-->
        </div> 
        <v-row v-else justify="center"> акции нет </v-row>
    </v-container>
</template>
<script>
import AddAction from './action/AddAction.vue';
import DeleteAction from './action/DeleteAction.vue';

export default {
    components: { AddAction, DeleteAction },
    data() {
        return {
            headers: ['название (en)', 'название (ru)', 'название (ro)', 'описание (en)', 'описание (ru)', 'описание (ro)', 'дата начала', 'дата конца', 'скидка', 'удалить акцию'],
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        action() {
            return this.$store.getters.action;
        },
    },
    methods: {
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleDateString(this.locale);
            }
            return 'no date'
        },
    },
}
</script>