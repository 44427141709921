<template>
  <v-container class="text-left pa-0" fluid>
    <div v-if="!loading">
      <v-card flat>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ 'responsible' | localize }} {{ firstName }} {{ lastName }}</v-toolbar-title>
            <span class="primary--text ml-auto">{{ 'log Out' | localize }}</span>
            <v-btn color="primary" icon small @click="logOut()">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        
        <v-tabs v-model="tab" background-color="primary" dark>
          <!---desktop-->
          <v-tab class=" text-lowercase mr-auto" dense v-for="(item, i) in items" :key="i">
            <span class="pl-2">{{ item }}</span>
          </v-tab>
            
        <!---orders-->
        <v-tab-item>
          <orders></orders>
        </v-tab-item>
        <!---products-->
        <v-tab-item>
          <products></products>
        </v-tab-item>
        <!---product-images-->
        <v-tab-item>
          <product-images></product-images>
        </v-tab-item>
        <!---articles-->
        <v-tab-item>
          <articles></articles>
        </v-tab-item>
         <!---reviews-->
          <v-tab-item>
            <reviews></reviews>
          </v-tab-item>
        <!---users-->
        <v-tab-item>
          <users></users>
        </v-tab-item>
         <!---actions-->
          <v-tab-item>
            <action></action>
          </v-tab-item>
      </v-tabs> 
      </v-card>
    </div>
    <div v-else>
      <loader />
    </div>
  </v-container>
</template>
<script>
import Loader from '../layouts/Loader.vue';
import localizeFilter from '../filters/localize.filter';

import Orders from '../components/dashboard/Orders.vue';
import Products from '../components/dashboard/Products.vue';
import Articles from '../components/dashboard/Articles.vue';
import Reviews from '../components/dashboard/Reviews.vue';
import ProductImages from '../components/dashboard/ProductImages.vue';
import Users from '../components/dashboard/Users.vue';
import Action from '../components/dashboard/Action.vue';

export default {
  data() {
    return {
      //props
      firstName: '',
      lastName: '',
      tab: 0,
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    userData() {
      return this.$store.getters.userData;
    },
    locale() {
      return this.$store.getters.locale;
    },
    items() {

      return [
        localizeFilter('orders'),
        localizeFilter('products'),
        localizeFilter('product images'),
        localizeFilter('articles'),
        localizeFilter('reviews'),
        localizeFilter('users'),
        localizeFilter('action')
      ]

    }
  },
  watch: {
    loggedIn() {
      if (!this.loggedIn) {
        this.$router.push('/signin');
      }
    }
  },
  methods: {
    dataChanged() {
      this.isChanged = true;
    },
    async logOut() {
      //get userId
      const uid = this.userData.id
      try {
        await this.$store.dispatch('logout', uid);
      }
      catch (error) {
        this.$store.dispatch('setError', error.message);
      }
    },
    isEmpty(obj) {
      for (var x in obj) {
        return false;
      }
      return true;
    },
  },
  components: { Loader, Orders, Products, Articles, Users, ProductImages, Reviews, Action },
  async created() {

    await this.$store.dispatch('fetchUserData');
    
    if (!this.isEmpty(this.userData) && this.userData.role == 'ADMIN') {
      this.firstName = this.userData.firstName;
      this.lastName = this.userData.lastName;

      try {
        await this.$store.dispatch('fetchOrders');
        await this.$store.dispatch('fetchArticles');
        await this.$store.dispatch('fetchProducts');
        await this.$store.dispatch('fetchAllUsers');
        await this.$store.dispatch('fetchReviews');
        await this.$store.dispatch('fetchAction');
      }
      catch (error) {
        this.$store.dispatch('setError', error.message)
      }

    }
    else {
      this.$store.dispatch('showSnackbar', {
        on: true,
        color: "white",
        text: "no access to enter",
        timeout: 2000
      })

      this.$router.replace('/');
    }

  }, 
}
</script>
